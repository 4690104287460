import { ErrorsContext } from '@/context/Errors/ErrorsContext';
import { useContext } from 'react';

const useErrors = () => {
  const errors = useContext(ErrorsContext);
  if (!errors) {
    throw new Error('useErrors must be used within a ErrorsProvider');
  }
  return errors;
};

export default useErrors;
