import { Element, ElementType, Elements } from '@moneyhash/js-sdk/headless';
import { useEffect, useRef } from 'react';
import styles from './Input.module.scss';
import { borderStyle, inputStyle } from './config';

interface Props {
  elements: Elements;
  name: ElementType;
  placeholder: string;
  error?: string;
  info?: string;
}

export const ElementsInput = ({ name, placeholder, elements, error, info }: Props) => {
  const inputElement = useRef<Element | null>(null);

  useEffect(() => {
    if (inputElement.current) {
      return;
    }
    inputElement.current = elements.create({
      elementType: name,
      elementOptions: {
        selector: `#${name}`,
        placeholder,
        styles: inputStyle,
      },
    });

    inputElement.current.mount();
  }, [elements.create, name, placeholder]);

  const className = `${styles.inputHelperText} ${error ? styles.error : ''}`;

  return (
    <div>
      <div id={name} className={styles.inputWrapper} style={{ border: borderStyle }}></div>
      <p className={className}>{error || info}</p>
    </div>
  );
};
