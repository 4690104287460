export const inputStyle = {
  color: '#323E48',
  backgroundColor: '#fff',
  placeholderColor: '#8B98A4',
  height: '24px',
  fontSize: '14px',
  padding: '0 2px',
  fontFamily: 'Lexend, sans-serif',
};

export const borderStyle = '1px solid #e2e8f0';
