import biryani from '@/assets/images/biry.png';
import cali from '@/assets/images/cali.png';
import circle from '@/assets/images/circle.webp';
import highjoint from '@/assets/images/highjoint.webp';
import hotbun from '@/assets/images/hotbun.webp';
import luca from '@/assets/images/luca.webp';
import operation from '@/assets/images/oper.png';
import pizzaro from '@/assets/images/pizzaro.webp';
import right from '@/assets/images/righ.png';
import shaw from '@/assets/images/shaw.webp';
import sushido from '@/assets/images/sushido.webp';
import taqado from '@/assets/images/taqa.png';
import under500 from '@/assets/images/under500.webp';
import zaroob from '@/assets/images/zaroob.webp';

import styles from './OurBrands.module.scss';

export const OurBrands = () => {
  const brands = [
    {
      logoUrl: right,
      title: 'Right Bite',
    },
    {
      logoUrl: taqado,
      title: 'Taqado',
    },
    {
      logoUrl: operation,
      title: 'Operation Falafel',
    },
    {
      logoUrl: cali,
      title: 'Cali-Poke',
    },
    {
      logoUrl: biryani,
      title: 'Biryani Pot',
    },
    {
      logoUrl: zaroob,
      title: 'Zaroob',
    },
    {
      logoUrl: circle,
      title: 'Circle Cafe',
    },
    {
      logoUrl: pizzaro,
      title: 'Pizzaro',
    },
    {
      logoUrl: sushido,
      title: 'Sushi Do',
    },
    {
      logoUrl: under500,
      title: 'Under 500',
    },
    {
      logoUrl: highjoint,
      title: 'High Joint',
    },
    {
      logoUrl: hotbun,
      title: 'Hot Bun',
    },
    {
      logoUrl: luca,
      title: 'Luca',
    },
    {
      logoUrl: shaw,
      title: 'Shawarma Station',
    },
  ];
  return (
    <div className={styles.root}>
      <h2>Earn points from over +150 brands</h2>
      <div className={styles.brands}>
        {brands.map(({ logoUrl, title }) => (
          <img key={logoUrl} src={logoUrl} alt={title} />
        ))}
      </div>
    </div>
  );
};
