import chevronUpIcon from '@/assets/images/chevronUp.svg';
import NoBill from '@/components/Bill/components/NoBill';
import BillSkeleton from '@/components/Skeletons/BillSkeleton';
import { currency } from '@/constants/constants';
import { useBill } from '@/hooks/useBill';
import { Position } from '@/interfaces/Bill';
import formatNumber from '@/utils/formatNumber';
import { useCallback, useState } from 'react';
import styles from './Bill.module.scss';

export const Bill = () => {
  const { data, isError, isPending } = useBill();
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});

  const toggleItem = useCallback((id: string) => {
    setExpandedItems(prev => ({
      ...prev,
      [id]: !prev[id],
    }));
  }, []);

  if (isPending) {
    return <BillSkeleton />;
  }

  if (isError) {
    return <NoBill />;
  }

  return (
    <div className={styles.root}>
      <h2>Dine in order</h2>
      <div className={styles.items}>
        {data.positions.map((position: Position) => (
          <div className={styles.positionSection} key={position.id}>
            <div className={styles.itemRow}>
              <div className={styles.quantity}>{position.quantity}x</div>
              <div>
                {position.name}
                {position?.modifiersV2?.length > 0 && (
                  <button
                    className={`${styles.chevron} ${expandedItems[position.id] ? styles.open : ''}`}
                    onClick={() => {
                      toggleItem(position.id);
                    }}
                  >
                    <img src={chevronUpIcon} alt="Collapse icon" />
                  </button>
                )}
              </div>
              <div className={styles.itemRight}>
                {currency} {formatNumber(position.unitPrice * position.quantity)}
              </div>
            </div>
            <div
              className={`${styles.modifiersRow} ${expandedItems[position.id] ? styles.expanded : ''}`}
            >
              {position.modifiersV2?.map(({ name, quantity, unitPrice }) => (
                <div key={name} className={styles.modifier}>
                  <div className={styles.modifierName}>
                    {name}
                    {quantity > 1 ? ` (x${quantity})` : ''}
                  </div>
                  <div className={styles.modifierPrice}>
                    {unitPrice ? (
                      <>
                        + {currency} {formatNumber(unitPrice * quantity)}
                      </>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
