import { Bill } from '@/components/Bill/Bill';
import BrandPresentation from '@/components/BrandPresentation/BrandPresentation';
import CheckoutPayment from '@/components/CheckoutPayment/CheckoutPayment';
import { MoneyHashPayments } from '@/components/PaymentMoneyHashSdk/MoneyHashPayments';
import Summary from '@/components/Summary/Summary';
import Tips from '@/components/Tips/Tips';
import {
  VITE_APP_ENV,
  VITE_MONEYHASH_OUTLET_IDS,
  countryCode,
  currency,
} from '@/constants/constants';
import { ErrorsProvider } from '@/context/Errors/ErrorsContext';
import { PaymentProvider } from '@/context/Payment/PaymentContext';
import { useBill } from '@/hooks/useBill';
import { useBrand } from '@/hooks/useBrand';
import { usePayment } from '@/hooks/usePayment';
import useValidateQueryParams from '@/hooks/useValidateQueryParams';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Checkout.module.scss';

const OUTLET_IDS_WITH_MONEYHASH = VITE_MONEYHASH_OUTLET_IDS?.split(',') || [];

const CheckoutContent = () => {
  const { isValid, queryParams } = useValidateQueryParams();
  const { paymentState } = usePayment();
  const { outletId, tableNumber } = queryParams();
  const { data: brandData } = useBrand();
  const { data: bill } = useBill();
  const navigate = useNavigate();

  const brandName = brandData?.name || 'Kitopay';
  const baseUrl = window.location.origin;

  const successUrl = useMemo(() => {
    if (bill) {
      return `${baseUrl}/checkout/success/?order=${bill.orderId}&brand=${brandName}&cashback=${bill.estimatedCashback}&outletId=${outletId}&tableNumber=${tableNumber}`;
    }
    return `${baseUrl}/checkout/success`;
  }, [bill, tableNumber, outletId, brandName, baseUrl]);

  const failedUrl = `${baseUrl}/checkout/failed`;

  const onPaymentResult = useCallback(
    (result: { success: boolean; message?: string }) => {
      if (result?.success) {
        navigate(successUrl);
      } else {
        // todo add error message
        navigate(`${failedUrl}?message=${result.message}`);
      }
    },
    [navigate, successUrl, failedUrl]
  );

  const paymentsProps = useMemo(
    () => ({
      currency,
      countryCode,
      amount: paymentState.total,
      onResult: onPaymentResult,
      successRedirectUrl: successUrl,
      failedRedirectUrl: failedUrl,
    }),
    [paymentState.total, onPaymentResult, successUrl, failedUrl]
  );

  if (!isValid())
    return (
      <div>
        Error loading table information.
        <br />
        Please scan QR code again
      </div>
    );

  const isMoneyHashPayments =
    outletId && bill && paymentState.total > 0 && OUTLET_IDS_WITH_MONEYHASH.includes(outletId);
  const baseWebHookUrl =
    VITE_APP_ENV === 'prod'
      ? 'https://api.kitopiconnect.com'
      : VITE_APP_ENV === 'stage'
        ? 'https://api.stage.kitopiconnect.com'
        : 'https://api.dev.kitopiconnect.com';

  return (
    <div className={styles.root}>
      <div className={styles.orderInfo}>
        <div>
          <BrandPresentation />
          <Bill />
        </div>
        <Tips />
        <Summary haveToShowCashbackBanner />
      </div>

      {isMoneyHashPayments ? (
        <div
          style={{
            backgroundColor: 'white',
            padding: '24px 16px',
          }}
        >
          <h1>Payments</h1>
          <MoneyHashPayments
            {...paymentsProps}
            webhookUrl={`${baseWebHookUrl}/api/dine-in-public/payments-api/bills/${bill.orderId}/payments`}
          />
        </div>
      ) : (
        <ErrorsProvider>
          <CheckoutPayment />
        </ErrorsProvider>
      )}
    </div>
  );
};

const Checkout = () => (
  <PaymentProvider>
    <CheckoutContent />
  </PaymentProvider>
);

export default Checkout;
