import styles from './SummaryRow.module.scss';

interface Props {
  label: string;
  value: string;
  discount?: boolean;
}

const SummaryRow = ({ discount, label, value }: Props) => {
  return (
    <div className={`${styles.row} ${discount ? styles.discount : ''}`}>
      <h3>{label}</h3>
      <div>{value}</div>
    </div>
  );
};

export default SummaryRow;
