import styles from './SummarySkeleton.module.scss';

const SummarySkeleton = () => {
  return (
    <div className={styles.root}>
      <div className={styles.title}></div>
      <div className={styles.total}>
        <div className={styles.shimmer}></div>
      </div>
    </div>
  );
};

export default SummarySkeleton;
