import { getTableDetails } from '@/api/order';
import { queryKeys } from '@/constants/queryKeys';
import useValidateQueryParams from '@/hooks/useValidateQueryParams';
import { useQuery } from '@tanstack/react-query';

export const useBill = () => {
  const { queryParams } = useValidateQueryParams();
  const { outletId, tableNumber } = queryParams();

  if (!outletId || !tableNumber) {
    throw new Error('Outlet ID and table number are required');
  }

  return useQuery({
    queryKey: queryKeys.bill({ outletId, tableNumber }),
    queryFn: ({ signal }) => getTableDetails({ outletId, tableNumber, signal }),
    staleTime: 0,
    enabled: !!outletId && !!tableNumber,
  });
};
