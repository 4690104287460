import * as Sentry from '@sentry/react';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

const paymentsApiUrl = 'https://skos-payments-api.dev.kitopiconnect.com/api';

interface CreateIntentParams {
  amount: number;
  currency: string;
  successful_redirect_url: string;
  failed_redirect_url: string;
}

interface IntentResponse {
  paymentId: string;
}

const createIntent = (
  signal: AbortSignal,
  { amount, currency, successful_redirect_url, failed_redirect_url }: CreateIntentParams
) =>
  fetch(`${paymentsApiUrl}/payments-public/payments/intents`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      amount,
      currency,
      successful_redirect_url,
      failed_redirect_url,
      customFields: {},
      form: {
        hideFormHeaderMessage: true,
        hideHeader: true,
        hideAmountSidebar: true,
      },
    }),
    signal,
  }).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  });

export const useCreateIntent = (): UseMutationResult<IntentResponse, Error, CreateIntentParams> => {
  return useMutation({
    mutationFn: ({
      amount,
      currency,
      successful_redirect_url,
      failed_redirect_url,
    }: CreateIntentParams) => {
      const controller = new AbortController();
      const signal = controller.signal;
      return createIntent(signal, {
        amount,
        currency,
        successful_redirect_url,
        failed_redirect_url,
      });
    },
    onError: (err: Error) => {
      if (err.name === 'AbortError') {
        console.log('Mutation aborted');
      } else {
        Sentry.captureException(err);
      }
    },
  });
};
