import restaurant from '@/assets/images/restaurant.png';
import Button from '@/components/SharedButton/Button';
import { KITOPI_APP_STORE_ADJUST_REDIRECT } from '@/constants/appStores';
import styles from './NoBill.module.scss';

const NoBill = () => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.imageWrapper}>
          <img src={restaurant} alt="restaurant" className={styles.image} />
        </div>

        <h2 className={styles.title}>Looks like you haven't ordered yet</h2>
        <p className={styles.subheader}>Your bill will appear when you order</p>
      </div>
      <div className={styles.footer}>
        <p className={styles.ctaText}>Download the app and get 20% cashback after your meal!</p>
        <div className={styles.buttonWrapper}>
          <Button
            label={'Download Kitopi App'}
            onClick={() => window.open(KITOPI_APP_STORE_ADJUST_REDIRECT, '_blank')}
            logo
          />
        </div>
      </div>
    </div>
  );
};

export default NoBill;
