import { Nullable } from '@/interfaces/Nullable';
import { PaymentProviders, PaymentStatus } from '@/interfaces/Payment';
import { fetch } from '@/utils/fetch';

interface PaymentStatusProps {
  signal: AbortSignal;
  paymentId: Nullable<string>;
  guestId: Nullable<string>;
}

interface PaymentProvidersProps {
  signal: AbortSignal;
  pos: string;
}

interface PaymentDataProps {
  pos: string;
  orderId: string;
  params: unknown;
}

export const getPaymentStatus = ({
  signal,
  paymentId,
  guestId,
}: PaymentStatusProps): Promise<PaymentStatus> =>
  fetch
    .get(`payments-integration-public/guests/${guestId}/payments/${paymentId}`, { signal })
    .then(response => response.data);

export const getPaymentProviders = ({
  signal,
  pos,
}: PaymentProvidersProps): Promise<PaymentProviders> =>
  fetch
    .get(`payments-integration-public/point-of-sales/${pos}/providers`, { signal })
    .then(response => response.data);

export const postPaymentData = ({ pos, orderId, params }: PaymentDataProps) =>
  fetch.post(
    `payments-integration-public/point-of-sales/${pos}/orders/${orderId}/payments`,
    params
  );
