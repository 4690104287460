import { LONG_STALE_TIME } from '@/constants/queries';
import {
  QueryClient,
  QueryClientProvider as TanstackQueryClientProvider,
} from '@tanstack/react-query';

interface Props {
  children: React.ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: LONG_STALE_TIME,
    },

    mutations: {
      retry: false,
    },
  },
});

const QueryClientProvider = ({ children }: Props) => {
  return <TanstackQueryClientProvider client={queryClient}>{children}</TanstackQueryClientProvider>;
};

export default QueryClientProvider;
