import { postApplePayToken } from '@/api/applePay';
import { queryKeys } from '@/constants/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const usePostApplePay = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postApplePayToken,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKeys.postApplePay] }),
  });
};
