import { currency } from '@/constants/constants';
import formatNumber from '@/utils/formatNumber';
import clsx from 'clsx';
import styles from './TipChip.module.scss';

interface Props {
  selected: boolean;
  onClick: () => void;
  amount: number;
  percentage: number;
}

const TipChip = ({ selected, onClick, amount, percentage }: Props) => {
  return (
    <div>
      {percentage === 7 && <div className={styles.tipLabel}>Most common</div>}
      <div
        className={clsx(styles.root, { [styles.selected]: selected })}
        data-selected={selected.toString()}
        data-testid={`tip-chip-${percentage}`}
        onClick={onClick}
      >
        {selected && (
          <div className={styles.closeIcon} onClick={onClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16px"
              height="16px"
              fill="#00819d"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414L13.414 12l4.95 4.95-1.414 1.414L12 13.414l-4.95 4.95-1.414-1.414L10.586 12 5.636 7.05l1.414-1.414z" />
            </svg>
          </div>
        )}
        {percentage !== 0 ? (
          <>
            <div className={styles.percentage}>{percentage}%</div>
            <div className={styles.amount}>
              {currency} {formatNumber(amount)}
            </div>
          </>
        ) : (
          <>
            <div className={styles.percentage}>
              No,
              <br /> Thanks
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TipChip;
