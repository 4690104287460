import Errors from '@/components/Errors/Errors';
import Terms from '@/components/Terms/Terms';
import useErrors from '@/hooks/useErrors';
import { usePayment } from '@/hooks/usePayment';
import usePaymentMethods from '@/hooks/usePaymentMethods';
import styles from './PaymentMethod.module.scss';

const PaymentMethod = () => {
  const { paymentState, setPaymentState } = usePayment();

  const { setErrorMessages } = useErrors();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const method =
      (event.target.value as 'apple-pay') ||
      (event.target.value as 'google-pay') ||
      (event.target.value as 'card');
    setPaymentState({ ...paymentState, paymentMethod: method });
    setErrorMessages([]);
  };

  const paymentMethods = usePaymentMethods();

  return (
    <div className={styles.root}>
      <h2>Payment method</h2>
      <div className={styles.radioGroup}>
        {paymentMethods.map(method => (
          <label key={method.value} className={styles.radioLabel}>
            <div className={styles.paymentPresentation}>
              <div className={styles.imgWrapper}>
                <img src={method.icon} alt={method.alt} className={styles.icon} />
              </div>
              {method.label}
            </div>
            <input
              type="radio"
              value={method.value}
              checked={paymentState.paymentMethod === method.value}
              onChange={handleRadioChange}
              aria-label={method.alt}
              className={styles.radioInput}
            />
          </label>
        ))}
      </div>

      {paymentState.paymentMethod !== 'card' && <Errors />}

      <Terms />
    </div>
  );
};

export default PaymentMethod;
