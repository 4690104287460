import { getPaymentProviders } from '@/api/payment';
import { queryKeys } from '@/constants/queryKeys';
import { useQuery } from '@tanstack/react-query';

export const usePaymentProviders = (pos: string) => {
  return useQuery({
    queryKey: [queryKeys.paymentProviders],
    queryFn: ({ signal }) => getPaymentProviders({ signal, pos }),
    enabled: !!pos,
  });
};
