import CardPayment from '@/components/CheckoutPayment/components/CardPayment';
import { useCheckoutPaymentState } from '@/components/CheckoutPayment/hooks/useCheckoutPaymentState';
import styles from './CheckoutPayment.module.scss';

import ApplePay from '@/components/CheckoutPayment/components/ApplePay';
import GooglePay from '@/components/CheckoutPayment/components/GooglePay';
import PaymentMethod from '@/components/PaymentMethod/PaymentMethod';
import { useBill } from '@/hooks/useBill';
import { usePayment } from '@/hooks/usePayment';

const CheckoutPayment = () => {
  const { paymentState } = usePayment();
  const { bill } = useCheckoutPaymentState();
  const { isError } = useBill();

  if (!bill || isError) return null;

  return (
    <div className={styles.root}>
      <PaymentMethod />

      {paymentState.paymentMethod === 'card' && <CardPayment />}
      {paymentState.paymentMethod === 'apple-pay' && <ApplePay />}
      {paymentState.paymentMethod === 'google-pay' && <GooglePay />}
    </div>
  );
};

export default CheckoutPayment;
