import styles from './Cashback.module.scss';

interface Props {
  amount: string;
  currency: string;
}

const Cashback = ({ currency, amount }: Props) => {
  return (
    <div className={styles.cashbackContainer}>
      <div className={styles.coins} />
      <div className={styles.cashback}>
        <div className={styles.cashbackLabel}>💰 You’ll earn</div>
        <div className={styles.cashbackAmount}>
          {currency} {amount} Cashback in the app
        </div>
      </div>
    </div>
  );
};

export default Cashback;
