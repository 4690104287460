import successIcon from '@/assets/images/success.svg';
import styles from './SuccessTitle.module.scss';

export const SuccessTitle = () => {
  return (
    <div className={styles.root}>
      <img src={successIcon} alt="Success icon" />
      <h1 className={styles.title}>Payment successful!</h1>
    </div>
  );
};
