import { Bill } from '@/interfaces/Bill';
import { fetch } from '@/utils/fetch';

interface Props {
  outletId: string;
  tableNumber: string;
  signal: AbortSignal;
}

export const getTableDetails = ({ outletId, tableNumber, signal }: Props): Promise<Bill> =>
  fetch
    .get(`/personalization-public/dine-in/outlets/${outletId}/tables/${tableNumber}`, { signal })
    .then(response => response.data);
