import { useEffect } from 'react';

const NotFound = () => {
  useEffect(() => {
    (window.dataLayer || []).push({
      event: 'not_found',
    });
  }, []);

  return <div>404 NotFound</div>;
};

export default NotFound;
