import styles from './TipsSkeleton.module.scss';

const TipsSkeleton = () => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div></div>
        <div></div>
      </div>
      <div className={styles.tipsWrapper}>
        <div className={styles.tips}>
          <div className={styles.chip} />
          <div className={styles.chip} />
          <div className={styles.chip} />
        </div>
      </div>
    </div>
  );
};

export default TipsSkeleton;
