import { useEffect } from 'react';
import styles from './ThreeDSecureFrame.module.scss';

interface Props {
  htmlContent: string;
  height?: string;
  onClose: () => void;
  onSuccess: () => void;
}

export const ThreeDSecureFrame = ({ htmlContent, height = '400px', onClose, onSuccess }: Props) => {
  const onMessage = (event: MessageEvent) => {
    if (!Array.isArray(event.data)) {
      return;
    }

    const [action, status] = event.data;

    if (action !== 'redirectionCompleted') {
      return;
    }

    if (status === 'FAILED') {
      onClose();
      return;
    }
    onSuccess();
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return (
    <iframe
      id="ThreeDSecureFrame"
      className={styles.frame}
      src={htmlContent}
      height={height}
      width="100%"
    />
  );
};
