const useValidateQueryParams = () => {
  const searchParams = new URLSearchParams(location.search);
  const outletId = searchParams.get('outletId');
  const brandId = searchParams.get('brandId');
  const tableNumber = searchParams.get('tableNumber')
    ? searchParams.get('tableNumber')?.toUpperCase()
    : '';

  const queryParamsStrict = () => {
    if (!outletId || !tableNumber || !brandId) {
      throw new Error('Invalid query params');
    }
    return { outletId, tableNumber, brandId };
  };

  const queryParams = () => {
    const outletId = searchParams.get('outletId');
    const intentId = searchParams.get('intentId');
    const tableNumber = searchParams.get('tableNumber')
      ? searchParams.get('tableNumber')?.toUpperCase()
      : '';
    const brandId = searchParams.get('brandId');
    return { outletId, tableNumber, brandId, intentId };
  };

  const isValid = () => {
    const { outletId, tableNumber, brandId } = queryParams();
    return !!outletId && !!tableNumber && !!brandId;
  };

  return { isValid, queryParams, queryParamsStrict };
};

export default useValidateQueryParams;
