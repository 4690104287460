import styles from './BillSkeleton.module.scss';

const ItemRowSkeleton = () => {
  return (
    <div className={styles.itemRow}>
      <div className={styles.quantity}></div>
      <div></div>
      <div className={styles.itemRight}></div>
    </div>
  );
};

const BillSkeleton = () => {
  return (
    <div className={styles.root} data-testid="bill-skeleton">
      <div className={styles.title}></div>
      <div className={styles.items}>
        <ItemRowSkeleton />
        <ItemRowSkeleton />
        <ItemRowSkeleton />
      </div>
    </div>
  );
};

export default BillSkeleton;
