import {
  VITE_APPLE_PAY_MERCHANT_VALIDATOR_ENDPOINT,
  VITE_GOOGLE_APPLE_PAY_CHECKOUT_URL,
} from '@/constants/constants';
import { ApplePayParams } from '@/interfaces/ApplePay';
import { AppleSessionResponse } from '@/interfaces/AppleSessionResponse';
import { fetch } from '@/utils/fetch';

interface ApplePayTokenProps {
  params: ApplePayParams;
  publicKey: string;
}

export const postApplePayToken = async ({
  params,
  publicKey,
}: ApplePayTokenProps): Promise<string> => {
  const response = await fetch.post(VITE_GOOGLE_APPLE_PAY_CHECKOUT_URL, params, {
    headers: {
      Authorization: `Bearer ${publicKey}`,
    },
  });
  return response.data.token;
};

interface ApplePayMerchantSessionProps {
  displayName: string;
}

export const postApplePayMerchantSession = async ({
  displayName,
}: ApplePayMerchantSessionProps): Promise<AppleSessionResponse> => {
  const response = await fetch.post(VITE_APPLE_PAY_MERCHANT_VALIDATOR_ENDPOINT, { displayName });
  return response.data;
};
