import usePaymentStatusRedirection from '@/components/CheckoutPayment/hooks/usePaymentStatusRedirection';
import { usePostApplePay } from '@/hooks/useApplePay';
import { useBill } from '@/hooks/useBill';
import { useBrand } from '@/hooks/useBrand';
import { usePostGooglePay } from '@/hooks/useGooglePay';
import { usePayment } from '@/hooks/usePayment';
import { usePaymentProviders } from '@/hooks/usePaymentProviders';
import { usePaymentStatus } from '@/hooks/usePaymentStatus';
import { usePostPayment } from '@/hooks/usePostPayment';
import useValidateQueryParams from '@/hooks/useValidateQueryParams';
import { ApplePayParams } from '@/interfaces/ApplePay';
import { Nullable } from '@/interfaces/Nullable';
import { useEffect, useReducer, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type CheckoutPaymentState = {
  paymentId: Nullable<string>;
  publicKey: Nullable<string>;
  isButtonDisabled: boolean;
  errorMessages: string[];
  isPaymentLoading: boolean;
  brandName: Nullable<string>;
};

type Action =
  | { type: 'SET_PAYMENT_ID'; payload: Nullable<string> }
  | { type: 'SET_PUBLIC_KEY'; payload: Nullable<string> }
  | { type: 'SET_BUTTON_DISABLED'; payload: boolean }
  | { type: 'SET_ERROR_MESSAGES'; payload: string[] }
  | { type: 'SET_PAYMENT_LOADING'; payload: boolean }
  | { type: 'SET_BRAND_NAME'; payload: Nullable<string> };

const initialState: CheckoutPaymentState = {
  paymentId: null,
  publicKey: null,
  isButtonDisabled: true,
  errorMessages: [],
  isPaymentLoading: false,
  brandName: null,
};

const reducer = (state: CheckoutPaymentState, action: Action): CheckoutPaymentState => {
  switch (action.type) {
    case 'SET_PAYMENT_ID':
      return { ...state, paymentId: action.payload };
    case 'SET_PUBLIC_KEY':
      return { ...state, publicKey: action.payload };
    case 'SET_BUTTON_DISABLED':
      return { ...state, isButtonDisabled: action.payload };
    case 'SET_ERROR_MESSAGES':
      return { ...state, errorMessages: action.payload };
    case 'SET_PAYMENT_LOADING':
      return { ...state, isPaymentLoading: action.payload };
    case 'SET_BRAND_NAME':
      return { ...state, brandName: action.payload };
    default:
      return state;
  }
};

export const useCheckoutPaymentState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const guestIdRef = useRef<string>(uuidv4());
  const guestId = guestIdRef.current;
  const { data: bill } = useBill();
  const { pointOfSaleId: pos, orderId } = bill || {};
  const { data: paymentProvider } = usePaymentProviders(pos || '');
  const { data: paymentStatusData } = usePaymentStatus({
    paymentId: state.paymentId,
    guestId,
  });
  const { data: brandData } = useBrand();
  const { selectedTipAmount } = usePayment();
  const { queryParams } = useValidateQueryParams();
  const { outletId, tableNumber } = queryParams();

  useEffect(() => {
    if (brandData) {
      dispatch({ type: 'SET_BRAND_NAME', payload: brandData.name });
    }
  }, [brandData]);

  useEffect(() => {
    if (paymentProvider?.providers[0].publicKey) {
      dispatch({
        type: 'SET_PUBLIC_KEY',
        payload: paymentProvider.providers[0].publicKey,
      });
    }
  }, [paymentProvider]);

  if (!outletId || !tableNumber) {
    throw new Error('Outlet ID and table number are required');
  }

  const post = usePostPayment();

  const handlePaymentSuccess = (data: string, paymentSource: string) => {
    const params = {
      type: 'CHECKOUT_TOKEN',
      guestId,
      paymentSource,
      paymentToken: data,
      tipAmount: selectedTipAmount,
    };

    if (!bill) throw new Error();

    post.mutate(
      { pos: pos || '', orderId: orderId || '', params },
      {
        onSuccess: ({ data }) => {
          dispatch({ type: 'SET_PAYMENT_ID', payload: data.id });
        },
        onError: () => {
          dispatch({
            type: 'SET_ERROR_MESSAGES',
            payload: [...state.errorMessages, 'Payment failed. Please try again.'],
          });
          dispatch({ type: 'SET_PAYMENT_LOADING', payload: false });
        },
      }
    );
  };

  const handleCardTokenized = (event: { token: string }) => {
    handlePaymentSuccess(event.token, 'ONE_TIME_CARD');
  };

  const postGooglePay = usePostGooglePay();

  const handleGooglePaySuccess = (paymentData: google.payments.api.PaymentData) => {
    const { token } = paymentData.paymentMethodData.tokenizationData;
    const parsedToken = JSON.parse(token);

    const params = {
      type: 'googlepay',
      token_data: parsedToken,
    };

    postGooglePay.mutate(
      { params, publicKey: state.publicKey! },
      {
        onSuccess: data => handlePaymentSuccess(data, 'GOOGLE_PAY'),
        onError: () => {
          dispatch({
            type: 'SET_ERROR_MESSAGES',
            payload: [...state.errorMessages, 'Google Pay error'],
          });
        },
      }
    );
  };

  const postApplePay = usePostApplePay();

  const handleApplePaySuccess = (paymentData: ApplePayJS.ApplePayPaymentToken) => {
    const params: ApplePayParams = {
      type: 'applepay',
      token_data: paymentData,
    };

    postApplePay.mutate(
      { params, publicKey: state.publicKey! },
      {
        onSuccess: data => handlePaymentSuccess(data, 'APPLE_PAY'),
        onError: () => {
          dispatch({
            type: 'SET_ERROR_MESSAGES',
            payload: [...state.errorMessages, 'Apple Pay error'],
          });
        },
      }
    );
  };

  usePaymentStatusRedirection(paymentStatusData, bill, state, outletId, tableNumber);

  return {
    state,
    dispatch,
    guestId,
    bill,
    handleCardTokenized,
    handleGooglePaySuccess,
    handleApplePaySuccess,
  };
};
