import useErrors from '@/hooks/useErrors';
import styles from './Errors.module.scss';

const Errors = () => {
  const { errorMessages } = useErrors();

  return (
    <div className={styles.root}>
      {errorMessages.length > 0 && errorMessages.map((msg: string) => <p key={msg}>{msg}</p>)}
    </div>
  );
};

export default Errors;
