import { VITE_APP_ENV } from '@/constants/constants';
import {
  Elements,
  default as MoneyHash,
  default as MoneyHashHeadless,
} from '@moneyhash/js-sdk/headless';
import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import { useCreateIntent } from '../PaymentsMoneyHashEmbedded/useCreateIntent';
import { PaymentMoneyHashSdk } from './PaymentMoneyHashSdk';

interface Props {
  webhookUrl?: string;
  successRedirectUrl: string;
  failedRedirectUrl: string;
  amount: number;
  accentColor?: string;
  currency: string;
  countryCode: string;
  onResult: (res: { success: boolean; message?: string }) => void;
}

export const MoneyHashPayments = (props: Props) => {
  const moneyHashInstanceRef = useRef<MoneyHashHeadless<'payment'> | null>(null);
  const moneyHashElementsRef = useRef<Elements | null>(null);
  const [intentId, setIntentId] = useState<string | null>(null);
  const { mutate: createIntent } = useCreateIntent();
  const {
    amount,
    currency,
    countryCode,
    onResult,
    successRedirectUrl,
    failedRedirectUrl,
    accentColor,
  } = props;

  // Initialize moneyHash
  useEffect(() => {
    // Initialize the library only once
    if (moneyHashInstanceRef.current === null) {
      moneyHashInstanceRef.current = new MoneyHash<'payment'>({
        type: 'payment',
        googlePay: {
          environment: VITE_APP_ENV !== 'prod' ? 'TEST' : 'PRODUCTION',
        },
        onComplete: () => {
          onResult({ success: true });
        },
        onFail: ({ intent, transaction }) => {
          Sentry.captureMessage(JSON.stringify({ intent, transaction }));
          onResult({ success: false, message: 'Payment failed' });
        },
      });
      moneyHashElementsRef.current = moneyHashInstanceRef.current.elements({
        fontSourceCss: 'https://fonts.googleapis.com/css2?family=Lexend:wght@300',
        classes: {
          focus: 'border border-blue-600',
          error: 'border border-red-500',
        },
      });
    }
  }, [onResult]);

  // createIntent
  useEffect(() => {
    if (!intentId) {
      createIntent(
        {
          amount,
          currency,
          successful_redirect_url: successRedirectUrl,
          failed_redirect_url: failedRedirectUrl,
        },
        {
          onSuccess: data => {
            setIntentId(data.paymentId);
          },
          onError: err => {
            Sentry.captureMessage(`Failed to create payment intent ${JSON.stringify(err)}`);
            onResult({ success: false, message: 'Failed to create payment intent' });
          },
        }
      );
    }
  }, [amount, intentId, createIntent, currency, onResult, successRedirectUrl, failedRedirectUrl]);

  return (
    <>
      {intentId && moneyHashInstanceRef.current && moneyHashElementsRef.current ? (
        <PaymentMoneyHashSdk
          amount={amount}
          currency={currency}
          countryCode={countryCode}
          onResult={onResult}
          accentColor={accentColor}
          moneyHashInstance={moneyHashInstanceRef.current}
          moneyHashElements={moneyHashElementsRef.current}
          intentId={intentId}
        />
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};
