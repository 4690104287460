import styles from './InfoSection.module.scss';

interface Props {
  title: string;
  terms: {
    title: string;
    description: string;
  }[];
}

export const InfoSection = ({ title, terms }: Props) => {
  return (
    <div className={styles.root}>
      <h2>{title}</h2>
      <dl>
        {terms.map(({ title, description }) => (
          <div className={styles.term} key={title}>
            <dt key={title}>{title}</dt>
            <dd key={description}>{description}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};
