import { Elements } from '@moneyhash/js-sdk/headless';
import { ElementsExpire } from './ElementsExpire';
import { ElementsInput } from './ElementsInput';
import styles from './Input.module.scss';

export const CardFields = ({ elements }: { elements: Elements }) => {
  return (
    <div className={styles.cardContainer}>
      <ElementsInput name="cardHolderName" elements={elements} placeholder="Card holder name" />
      <ElementsInput name="cardNumber" elements={elements} placeholder="Card Number" />
      <div className={styles.cardExpiryCvvRow}>
        <div className={styles.cardEqualWidth}>
          <ElementsExpire elements={elements} />
        </div>
        <div className={styles.cardEqualWidth}>
          <ElementsInput name="cardCvv" elements={elements} placeholder="CVV" />
        </div>
      </div>
    </div>
  );
};
