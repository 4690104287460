import { ThreeDSecureFrame } from '@/components/Modal/ThreeDSecureFrame';
import { ReactNode } from 'react';
import styles from './Overlay.module.scss';

type ModalProps = {
  children: ReactNode;
  onClose: () => void;
  onSuccess: () => void;
  threeDSUrl: string;
};

const Modal = ({ threeDSUrl, onClose, onSuccess }: ModalProps) => {
  return (
    <div className={styles.overlay} onClick={onClose}>
      <ThreeDSecureFrame htmlContent={threeDSUrl} onClose={onClose} onSuccess={onSuccess} />
    </div>
  );
};

export default Modal;
