import CashbackEstimation from '@/components/CashbackEstimation/CashbackEstimation';
import { InfoSection } from '@/components/InfoSection/InfoSection';
import { OurBrands } from '@/components/OurBrands/OurBrands';
import PaidBill from '@/components/PaidBill/PaidBill';
import { SuccessTitle } from '@/components/SuccessTitle/SuccessTitle';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styles from './PaymentConfirmation.module.scss';

const PaymentConfirmation = () => {
  const query = new URLSearchParams(useLocation().search);
  const orderId = query.get('order') || '';
  const brandName = query.get('brand');
  const cashbackEstimate = query.get('cashback');

  useEffect(() => {
    (window.dataLayer || []).push({
      event: 'purchase_completed',
      orderId: orderId,
      brandName: brandName,
      success_status: '/checkout/success',
    });
  }, [orderId, brandName]);

  if (!orderId || !brandName || !cashbackEstimate) {
    return <Navigate to="/404" />;
  }

  return (
    <div className={styles.root}>
      <div className={styles.success}>
        <SuccessTitle />

        <PaidBill />

        <CashbackEstimation cashbackEstimate={cashbackEstimate} orderId={orderId} />

        <InfoSection
          title="Why Kitopi app"
          terms={[
            {
              title: 'Dine at our restaurants',
              description:
                'Enjoy a meal at any of our locations and receive cashback directly in the app',
            },
            {
              title: 'Order through the Kitopi app',
              description: 'Earn up to 20% cashback on everey order placed through our app',
            },
          ]}
        />

        <InfoSection
          title="Redeem your points effortlessly"
          terms={[
            {
              title: 'Kitopi app',
              description:
                'Use your accumulated points to pay for your next order directly through the Kitopi App',
            },
          ]}
        />

        <OurBrands />
      </div>
    </div>
  );
};

export default PaymentConfirmation;
