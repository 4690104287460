import { useCheckoutPaymentState } from '@/components/CheckoutPayment/hooks/useCheckoutPaymentState';
import Modal from '@/components/Modal/Modal';
import { usePayment } from '@/hooks/usePayment';
import { Bill } from '@/interfaces/Bill';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ReceiptData {
  bill: Bill;
}

interface Props {
  outletId: string;
  tableNumber: string;
}

const Modal3ds = ({ outletId, tableNumber }: Props) => {
  const { setPaymentState, paymentState } = usePayment();
  const { state, bill } = useCheckoutPaymentState();
  const navigate = useNavigate();
  const [receiptData, setReceiptData] = useState<ReceiptData | null>(null);

  useEffect(() => {
    if (bill) {
      setReceiptData({ bill });
    }
  }, [bill]);

  const handleSuccess = () => {
    if (
      receiptData &&
      (paymentState.paymentMethod === 'card' || paymentState.paymentMethod === 'google-pay')
    ) {
      setPaymentState({ ...paymentState, isModalOpen: false });
      navigate(
        `/checkout/success/?order=${receiptData.bill.orderId}&brand=${state.brandName}&cashback=${receiptData.bill.estimatedCashback}&outletId=${outletId}&tableNumber=${tableNumber}`
      );
    }
  };

  if (!receiptData || paymentState.paymentMethod === 'apple-pay') return null;

  return (
    <Modal
      onClose={() => setPaymentState({ ...paymentState, isModalOpen: false })}
      onSuccess={handleSuccess}
      threeDSUrl={paymentState.threeDSUrl}
    >
      <iframe src={paymentState.threeDSUrl} title="3DS Authentication" width="100%" height="100%" />
    </Modal>
  );
};

export default Modal3ds;
