import styles from './Terms.module.scss';

const Terms = () => {
  return (
    <p className={styles.terms}>
      By using pay.kitopi you accept our{' '}
      <a href="/assets/Terms_and_Conditions.pdf">Terms and Conditions</a>
    </p>
  );
};

export default Terms;
