import { postPaymentData } from '@/api/payment';
import { queryKeys } from '@/constants/queryKeys';
import { PaymentParams } from '@/interfaces/Payment';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface Props {
  pos: string;
  orderId: string;
  params: PaymentParams;
}

export const usePostPayment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ pos, orderId, params }: Props) => postPaymentData({ pos, orderId, params }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.postPayment] });
    },
  });
};
