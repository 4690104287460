import { Elements, Method, PaymentMethodSlugs } from '@moneyhash/js-sdk/headless';
import { useState } from 'react';
import { CardFields } from './CardFields/CardFields';
import styles from './PaymentMethods.module.scss';

export interface Props {
  elements: Elements;
  intentId: string;
  accentColor?: string;
  paymentMethods: Method[];
  onSelect: (id: PaymentMethodSlugs) => void;
}

export const PaymentMethods = ({ paymentMethods, onSelect, elements, accentColor }: Props) => {
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null);

  return (
    <div>
      {paymentMethods.map(({ id, title, icons }) => (
        <div key={id} className={styles.paymentMethodRow}>
          <label>
            <div className={styles.icon}>
              {icons[0] ? <img src={icons[0]} alt={title} /> : '🪙'}
            </div>
            <h2>{title}</h2>
            <input
              type="radio"
              onChange={() => {
                onSelect(id);
                setSelectedMethod(id);
              }}
              className={styles.radioInput}
              style={
                {
                  '--radio-input-color': accentColor,
                } as React.CSSProperties
              }
              name="payment-method"
              value={id}
            />
          </label>
          {selectedMethod === id && id === 'CARD' && <CardFields elements={elements} />}
        </div>
      ))}
    </div>
  );
};
