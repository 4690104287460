import { currency } from '@/constants/constants';
import { PaymentProvider } from '@/context/Payment/PaymentContext';
import { useBill } from '@/hooks/useBill';
import useValidateQueryParams from '@/hooks/useValidateQueryParams';
import formatNumber from '@/utils/formatNumber';
import BillSkeleton from '../Skeletons/BillSkeleton';
import SummaryRow from '../Summary/SummaryRow';
import SummaryTotal from '../Summary/SummaryTotal';
import styles from './PaidBill.module.scss';
import { getCookieTip } from './getCookieTip';

const PaidBill = () => {
  const { queryParams } = useValidateQueryParams();
  const { tableNumber } = queryParams();
  const { data, isLoading } = useBill();
  const selectedTipAmount = getCookieTip();

  return (
    <div className={styles.root}>
      <div className={styles.successBill}>
        <div className={styles.headerSuccessBill}>
          <p>Table Dine in {tableNumber?.replace('T', '')}</p>
          <span className={styles.greenChip}>Fully paid</span>
        </div>
        {isLoading || !data ? (
          <BillSkeleton />
        ) : (
          <>
            <SummaryRow label="Subtotal" value={`${currency} ${formatNumber(data.total)}`} />
            <SummaryRow label="Tips" value={`${currency} ${formatNumber(selectedTipAmount)}`} />
            <SummaryTotal
              label="Total Amount"
              value={`${currency} ${formatNumber((data.total || 0) + selectedTipAmount)}`}
            />
          </>
        )}{' '}
      </div>
    </div>
  );
};

export default () => (
  <PaymentProvider>
    <PaidBill />
  </PaymentProvider>
);
