import { Elements } from '@moneyhash/js-sdk/headless';
import { useEffect, useRef } from 'react';
import styles from './Input.module.scss';
import { borderStyle, inputStyle } from './config';

export const ElementsExpire = ({ elements }: { elements: Elements }) => {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const expiryMonthEl = useRef<any>(null);
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const expiryYearEl = useRef<any>(null);

  useEffect(() => {
    if (expiryMonthEl.current || expiryYearEl.current) {
      return;
    }

    expiryMonthEl.current = elements.create({
      elementType: 'cardExpiryMonth',
      elementOptions: {
        selector: `#cardExpiryMonth`,
        placeholder: 'Expiry MM',
        styles: inputStyle,
      },
    });

    expiryYearEl.current = elements.create({
      elementType: 'cardExpiryYear',
      elementOptions: {
        selector: `#cardExpiryYear`,
        placeholder: 'YY',
        styles: inputStyle,
      },
    });

    expiryMonthEl.current.mount();
    expiryYearEl.current.mount();
  }, [elements]);

  return (
    <div>
      <div className={styles.inputWrapper} style={{ border: borderStyle }}>
        <div id="cardExpiryMonth" className={styles.expirationMonth} />
        <span className={styles.divider}>/</span>
        <div id="cardExpiryYear" className={styles.expirationYear} />
      </div>
    </div>
  );
};
