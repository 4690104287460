import { Brand } from '@/interfaces/Brand';
import { Nullable } from '@/interfaces/Nullable';
import { fetch } from '@/utils/fetch';

interface Props {
  brandId: Nullable<string>;
  signal: AbortSignal;
}

export const getBrandDetails = ({ brandId, signal }: Props): Promise<Brand> =>
  fetch.get(`/eatopi-content-public/brands/${brandId}`, { signal }).then(response => response.data);
