import { postApplePayMerchantSession } from '@/api/applePay';
import { useCheckoutPaymentState } from '@/components/CheckoutPayment/hooks/useCheckoutPaymentState';
import useScrollIntoView from '@/components/CheckoutPayment/hooks/useScrollIntoView';
import Overlay from '@/components/Modal/Overlay';
import { countryCode, currency } from '@/constants/constants';
import useErrors from '@/hooks/useErrors';
import { usePayment } from '@/hooks/usePayment';
import * as Sentry from '@sentry/react';
import styles from './ApplePay.module.scss';

const ApplePayButton = () => {
  const { state, handleApplePaySuccess } = useCheckoutPaymentState();
  const { setErrorMessages } = useErrors();
  const buttonRef = useScrollIntoView(state.isButtonDisabled);
  const { paymentState, setPaymentState } = usePayment();

  const brandName = state.brandName || 'Kitopay';

  if (!paymentState.total) return null;

  const handleApplePayButtonClick = () => {
    setPaymentState(prevState => ({
      ...prevState,
      isCancelled: false,
      isOverlayVisible: true,
    }));

    const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
      countryCode,
      currencyCode: currency,
      supportedNetworks: ['visa', 'masterCard'],
      merchantCapabilities: ['supports3DS'],
      total: {
        label: brandName,
        type: 'final',
        amount: `${paymentState.total}`,
      },
    };

    const session = new ApplePaySession(3, paymentRequest);

    session.onvalidatemerchant = async () => {
      if (paymentState.isCancelled) {
        Sentry.captureException({
          message: 'Merchant validation canceled, we are aborting onvalidatemerchant',
          paymentState,
        });
        session?.abort();
        return;
      }

      try {
        const merchantSession = await postApplePayMerchantSession({
          displayName: 'Kitopay',
        });

        try {
          session.completeMerchantValidation(merchantSession);
        } catch (error) {
          Sentry.captureException({
            message: 'Merchant validation failed on completeMerchantValidation',
            merchantSession,
            error,
          });
          setErrorMessages(['Merchant validation failed. Please try again.']);
          setPaymentState(prevState => ({
            ...prevState,
            isOverlayVisible: false,
          }));
          session?.abort();
        }
      } catch (error) {
        console.error('An error occurred:', error);
        setErrorMessages(['Merchant validation failed. Please try again.']);
        setPaymentState(prevState => ({
          ...prevState,
          isOverlayVisible: false,
        }));
        session?.abort();
      }
    };

    session.onpaymentauthorized = event => {
      try {
        const { paymentData } = event.payment.token;
        session.completePayment(ApplePaySession.STATUS_SUCCESS);
        handleApplePaySuccess(paymentData);
      } catch (error) {
        console.error('An error occurred:', error);
        setErrorMessages(['Payment authorization failed. Please try again.']);
        session.completePayment(ApplePaySession.STATUS_FAILURE);
      } finally {
        setPaymentState(prevState => ({
          ...prevState,
          isOverlayVisible: false,
        }));
      }
    };

    session.oncancel = () => {
      setErrorMessages(['Payment was cancelled.']);
      setPaymentState(prevState => ({
        ...prevState,
        isCancelled: true,
        isOverlayVisible: false,
      }));
    };

    session.begin();
  };

  return (
    <div className={styles.root}>
      {paymentState.isOverlayVisible && <Overlay />}
      <button id="apple-pay-button" onClick={handleApplePayButtonClick} ref={buttonRef}></button>
    </div>
  );
};

export default ApplePayButton;
