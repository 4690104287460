import appleIcon from '@/assets/images/apple.svg';
import MoneyHashHeadless, {
  Elements,
  IntentStateDetails,
  Method,
  PaymentMethodSlugs,
} from '@moneyhash/js-sdk/headless';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { PaymentMethods } from './PaymentMethods';
import styles from './PaymentMoneyHashSdk.module.scss';
import { usePaymentHandlers } from './usePaymentHandlers';

interface PaymentsProps {
  webhookUrl?: string;
  amount: number;
  accentColor?: string;
  currency: string;
  countryCode: string;
  onResult: (res: { success: boolean; message?: string }) => void;
  moneyHashInstance: MoneyHashHeadless<'payment'>;
  moneyHashElements: Elements;
  intentId: string;
}

const GOOGLE_PAY_BTN_ID = 'moneyHash-google-pay-button';
const APPLE_PAY_BTN_ID = 'moneyHash-apple-pay-button';
const CARD_PAY_BTN_ID = 'moneyHash-card-pay-button';

type GoogleButtonOptions = {
  buttonColor?: google.payments.api.ButtonColor;
  buttonLocale?: string;
  buttonRadius?: number;
  buttonSizeMode?: google.payments.api.ButtonSizeMode;
  buttonType?: google.payments.api.ButtonType;
};

const GooglePayButtonStyles: GoogleButtonOptions = {
  buttonColor: 'black',
  buttonType: 'long',
  buttonSizeMode: 'fill',
  buttonRadius: 20,
};

export const PaymentMoneyHashSdk = (props: PaymentsProps) => {
  if (!props.moneyHashInstance) {
    throw new Error('moneyHashInstance is undefined');
  }
  const [methods, setMethods] = useState<Method[]>([]);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethodSlugs | null>(null);
  const {
    amount,
    currency,
    countryCode,
    onResult,
    accentColor,
    moneyHashInstance,
    moneyHashElements,
    intentId,
  } = props;

  const { payWithApplePay, payWithCard, payWithGooglePay } = usePaymentHandlers({
    moneyHashInstance,
    intentId,
    currency,
    countryCode,
    amount,
    onResult,
  });

  // getIntentMethods
  useEffect(() => {
    moneyHashInstance
      .getIntentMethods(intentId)
      .then(({ paymentMethods, expressMethods }) => {
        setMethods([...paymentMethods, ...expressMethods]);
      })
      .catch(err => {
        Sentry.captureMessage(`Failed to get payment methods ${JSON.stringify(err)}`);
        onResult({ success: false, message: 'Failed to get payment methods' });
      });
  }, [intentId, onResult, moneyHashInstance.getIntentMethods]);

  // effect on change selected pay method
  useEffect(() => {
    if (selectedMethod === 'GOOGLE_PAY') {
      moneyHashInstance.renderGooglePayButton({
        ...GooglePayButtonStyles,
        onClick: () => {
          payWithGooglePay();
        },
      });
    }
  }, [selectedMethod, moneyHashInstance.renderGooglePayButton, payWithGooglePay]);

  const onSelectPaymentMethod = (method: PaymentMethodSlugs) => {
    if (selectedMethod !== 'CARD' && method === 'CARD') {
      const googlePayButton = document.getElementById(GOOGLE_PAY_BTN_ID);
      const applePayButton = document.getElementById(APPLE_PAY_BTN_ID);
      if (googlePayButton) googlePayButton.innerHTML = '';
      if (applePayButton) applePayButton.innerHTML = '';
    }

    setSelectedMethod(method);
    moneyHashInstance
      .proceedWith({
        intentId,
        type: 'method',
        id: method,
      })
      .then(res => {
        const { stateDetails } = res;
        const { formFields } = stateDetails as unknown as IntentStateDetails<'FORM_FIELDS'>;

        if (formFields && method === 'CARD' && formFields.card) {
          setAccessToken(formFields.card.accessToken);
        }
      });
  };

  return (
    <div>
      <PaymentMethods
        accentColor={accentColor}
        elements={moneyHashElements}
        paymentMethods={methods}
        intentId={intentId}
        onSelect={onSelectPaymentMethod}
      />

      <div id={GOOGLE_PAY_BTN_ID} className={styles.hiddenWhenEmpty}></div>

      <div id={APPLE_PAY_BTN_ID} className={styles.hiddenWhenEmpty}>
        {selectedMethod === 'APPLE_PAY' && (
          <button
            className={`${styles.payButton} ${styles.applePayButton}`}
            onClick={() => payWithApplePay()}
          >
            <img src={appleIcon} alt="Apple Pay" className={styles.icon} />
            Pay
          </button>
        )}
      </div>

      <div id={CARD_PAY_BTN_ID} className={styles.hiddenWhenEmpty}>
        {selectedMethod === 'CARD' && accessToken && (
          <button className={styles.payButton} onClick={() => payWithCard(accessToken)}>
            Pay
          </button>
        )}
      </div>

      <div id="rendered-url-iframe-container"></div>
    </div>
  );
};
