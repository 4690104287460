import TipsSkeleton from '@/components/Skeletons/TipsSkeleton';
import TipChip from '@/components/Tips/components/TipChip/TipChip';
import { useBill } from '@/hooks/useBill';
import { usePayment } from '@/hooks/usePayment';
import { TIP_OPTIONS } from '@/utils/constants';
import { useEffect } from 'react';
import styles from './Tips.module.scss';

const Tips = () => {
  const { isLoading, data, isError } = useBill();
  const { paymentState, setPaymentState, tipsAmount } = usePayment();

  const handleTipClick = (index: number) => {
    const isSelected = paymentState.tipSelected === index;

    setPaymentState({
      ...paymentState,
      tipSelected: isSelected ? null : index,
    });
    document.cookie = `tip=${tipsAmount[index]}; path=/;`;
  };

  const tip = tipsAmount[paymentState.tipSelected || 0];

  useEffect(() => {
    document.cookie = `tip=${tip}; path=/;`;
  }, [tip]);

  if (isLoading) {
    return <TipsSkeleton />;
  }

  if (isError || !data) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2>Tip your server</h2>
        <h3>100% of your tip will go to your server</h3>
      </div>
      <div className={styles.tipsWrapper}>
        <div className={styles.tips}>
          {TIP_OPTIONS.map((tip, index) => (
            <TipChip
              key={tip}
              amount={tipsAmount[index] || 0}
              percentage={tip}
              onClick={() => handleTipClick(index)}
              selected={index === paymentState.tipSelected}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tips;
