import { CheckoutPaymentState } from '@/components/CheckoutPayment/hooks/useCheckoutPaymentState';
import { VITE_GOOGLE_PAY_ENV, countryCode, currency } from '@/constants/constants';
import { GOOGLE_PAY_MERCHANT_ID } from '@/constants/googlePay';

export const getGooglePayButtonOptions = (
  state: CheckoutPaymentState,
  total: number
): {
  environment: google.payments.api.Environment;
  buttonSizeMode: 'fill';
  style: React.CSSProperties;
  buttonRadius: number;
  buttonType: 'plain';
  paymentRequest: google.payments.api.PaymentDataRequest;
} => ({
  environment: VITE_GOOGLE_PAY_ENV,
  buttonSizeMode: 'fill',
  style: { width: '100%', height: 60 },
  buttonRadius: 999,
  buttonType: 'plain',
  paymentRequest: {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['MASTERCARD', 'VISA'],
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'checkoutltd',
            gatewayMerchantId: state.publicKey || '',
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: GOOGLE_PAY_MERCHANT_ID,
      merchantName: 'Kitopi',
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: `${total}`,
      currencyCode: currency,
      countryCode,
    },
  },
});
