import { getPaymentStatus } from '@/api/payment';
import { queryKeys } from '@/constants/queryKeys';
import { Nullable } from '@/interfaces/Nullable';
import { PaymentStatus } from '@/interfaces/Payment';
import { useQuery } from '@tanstack/react-query';

interface Props {
  paymentId: Nullable<string>;
  guestId: string;
}

export const usePaymentStatus = ({ paymentId, guestId }: Props) => {
  return useQuery<PaymentStatus>({
    queryKey: queryKeys.paymentStatus({ guestId, paymentId }),
    queryFn: ({ signal }) => getPaymentStatus({ signal, guestId, paymentId }),
    staleTime: 0,
    enabled: !!paymentId && !!guestId,
    refetchInterval: paymentId ? 500 : false,
  });
};
