import { getBrandDetails } from '@/api/brand';
import { LONG_STALE_TIME } from '@/constants/queries';
import { queryKeys } from '@/constants/queryKeys';
import useValidateQueryParams from '@/hooks/useValidateQueryParams';
import { useQuery } from '@tanstack/react-query';

export const useBrand = () => {
  const { queryParams } = useValidateQueryParams();
  const { brandId } = queryParams();

  return useQuery({
    queryKey: queryKeys.brand({ brandId }),
    queryFn: ({ signal }) => getBrandDetails({ brandId, signal }),
    staleTime: LONG_STALE_TIME,
    enabled: !!brandId,
  });
};
