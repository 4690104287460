export const getCookieTip = () => {
  const tip = document.cookie
    .split('; ')
    .find(row => row.startsWith('tip='))
    ?.split('=')[1];
  if (tip && isNaN(parseFloat(tip))) {
    return 0;
  }

  if (tip && parseFloat(tip) < 0) {
    return 0;
  }

  return tip ? parseFloat(tip) : 0;
};
