import { VITE_GOOGLE_APPLE_PAY_CHECKOUT_URL } from '@/constants/constants';
import { GooglePayParams } from '@/interfaces/GooglePay';
import { fetch } from '@/utils/fetch';

interface Props {
  params: GooglePayParams;
  publicKey: string;
}

export const getGooglePayToken = async ({ params, publicKey }: Props): Promise<string> => {
  const response = await fetch.post(VITE_GOOGLE_APPLE_PAY_CHECKOUT_URL, params, {
    headers: {
      Authorization: `Bearer ${publicKey}`,
    },
  });
  return response.data.token;
};
