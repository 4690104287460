/* eslint-disable no-console */
import applePayIcon from '@/assets/images/applePayMark.png';
import cardIcon from '@/assets/images/cardMark.png';
import googlePayIcon from '@/assets/images/googlePayMark.png';
import { VITE_APPLE_PAY_AVAILABLE } from '@/constants/constants';
import { isApplePayAvailable } from '@/utils/isApplePayAvailable';
import { useEffect, useState } from 'react';

const usePaymentMethods = () => {
  const [isApplePayAccesible, setIsApplePayAccesible] = useState(false);

  const BASE_PAYMENT_METHODS = [
    { value: 'card', label: 'Card', icon: cardIcon, alt: 'Card' },
    {
      value: 'google-pay',
      label: 'Google Pay',
      icon: googlePayIcon,
      alt: 'Google Pay',
    },
  ];

  useEffect(() => {
    const checkApplePayAvailability = async () => {
      try {
        if (isApplePayAvailable()) {
          const canMakePayments = await ApplePaySession.canMakePayments();
          setIsApplePayAccesible(canMakePayments);
        }
      } catch (error) {
        console.error('Error checking Apple Pay availability:', error);
      }
    };

    checkApplePayAvailability();
  }, []);

  const paymentMethods =
    isApplePayAccesible && VITE_APPLE_PAY_AVAILABLE === 'true'
      ? [
          {
            value: 'apple-pay',
            label: 'Apple Pay',
            icon: applePayIcon,
            alt: 'Apple Pay',
          },
          ...BASE_PAYMENT_METHODS,
        ]
      : BASE_PAYMENT_METHODS;

  return paymentMethods;
};

export default usePaymentMethods;
