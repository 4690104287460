import Coins from '@/assets/images/coins.png';
import { DEFERRED_BASE_URL } from '@/constants/config';
import { currency } from '@/constants/constants';
import { Nullable } from '@/interfaces/Nullable';
import formatNumber from '@/utils/formatNumber';
import Button from '../SharedButton/Button';
import styles from './CashbackEstimation.module.scss';

interface Props {
  cashbackEstimate: Nullable<string>;
  orderId: string;
}

const CashbackEstimation = ({ cashbackEstimate, orderId }: Props) => {
  if (!cashbackEstimate) return null;

  const deferredLink = `${DEFERRED_BASE_URL}${orderId}`;

  return (
    <div className={styles.root}>
      <div className={styles.coinsWrapper}>
        <div className={styles.cashbackText}>
          Your Cashback
          <span>
            {currency} {formatNumber(cashbackEstimate)}
          </span>
        </div>
        <div className={styles.coinsRightWrapper}>
          <img src={Coins} alt="Coins" />
        </div>
      </div>
      <div className={styles.appButton}>
        <Button
          onClick={() => window.open(deferredLink, '_blank')}
          label="Download Kitopi App to claim"
        />
      </div>
    </div>
  );
};

export default CashbackEstimation;
