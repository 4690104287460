import { createContext, useState } from 'react';

interface ErrorsContextProps {
  errorMessages: string[];
  setErrorMessages: (messages: string[]) => void;
}

export const ErrorsContext = createContext<ErrorsContextProps | undefined>(undefined);

export const ErrorsProvider = ({ children }: { children: React.ReactNode }) => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  return (
    <ErrorsContext.Provider value={{ errorMessages, setErrorMessages }}>
      {children}
    </ErrorsContext.Provider>
  );
};
