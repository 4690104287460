import * as Sentry from '@sentry/react';
import packageJson from '../../package.json';

const release = packageJson.version;

Sentry.init({
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  release,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
