import { API_BASE_URL } from '@/constants/config';
import axios, { AxiosRequestConfig } from 'axios';

const defaultOptions: AxiosRequestConfig = {
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeoutErrorMessage: 'timeout error',
};

export const fetch = axios.create(defaultOptions);
