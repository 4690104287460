import { getGooglePayToken } from '@/api/googlePay';
import { queryKeys } from '@/constants/queryKeys';
import { GooglePayParams } from '@/interfaces/GooglePay';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface Props {
  params: GooglePayParams;
  publicKey: string;
}

export const usePostGooglePay = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ params, publicKey }: Props) => {
      const token = await getGooglePayToken({ params, publicKey });
      return token;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.postGooglePay] });
    },
  });
};
