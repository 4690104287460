import { useBill } from '@/hooks/useBill';
import { Nullable } from '@/interfaces/Nullable';
import calculateTipsAmount from '@/utils/calculateTipsAmount';
import { DEFAULT_TIP, TIP_OPTIONS } from '@/utils/constants';
import { detectHardware } from '@/utils/detectHardware';
import { ReactNode, createContext, useMemo, useState } from 'react';

type CardPaymentState = {
  paymentMethod: 'card' | 'google-pay';
  isModalOpen: boolean;
  threeDSUrl: string;
  tipSelected: Nullable<number>;
  total: number;
  isOverlayVisible: boolean;
  isCancelled: boolean;
};

type ApplePayPaymentState = {
  paymentMethod: 'apple-pay';
  tipSelected: Nullable<number>;
  total: number;
  isOverlayVisible: boolean;
  isCancelled: boolean;
};

type PaymentState = CardPaymentState | ApplePayPaymentState;

export interface PaymentContextProps {
  paymentState: PaymentState;
  setPaymentState: React.Dispatch<React.SetStateAction<PaymentState>>;
  selectedTipAmount: number;
  tipsAmount: number[];
}

export const PaymentContext = createContext<PaymentContextProps | undefined>(undefined);

const payment = detectHardware();

export const PaymentProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useBill();
  const tipsAmount = useMemo(
    () =>
      data
        ? calculateTipsAmount(data.total, TIP_OPTIONS)
        : Array.from({ length: TIP_OPTIONS.length }, () => 0),
    [data]
  );

  const [paymentState, setPaymentState] = useState<PaymentState>({
    paymentMethod: payment,
    isCancelled: false,
    isModalOpen: false,
    threeDSUrl: '',
    tipSelected: DEFAULT_TIP, // default tip 7% (second index)
    total: 0,
    isOverlayVisible: false,
  });

  const selectedTipAmount =
    paymentState.tipSelected !== null ? tipsAmount[paymentState.tipSelected] : 0;

  return (
    <PaymentContext.Provider
      value={{ paymentState, setPaymentState, selectedTipAmount, tipsAmount }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
