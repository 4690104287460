import kitopiLogo from '@/assets/images/kitopiLogo.png';
import clsx from 'clsx';
import { forwardRef } from 'react';
import styles from './Button.module.scss';

interface SharedButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  logo?: boolean;
  secondary?: boolean;
}

const Button = forwardRef<HTMLButtonElement, SharedButtonProps>(
  ({ label, onClick, disabled, loading, logo, secondary }, ref) => {
    const children = logo ? (
      <div className={styles.logoEnabled}>
        <img src={kitopiLogo} alt="logo" /> <span>{label}</span>
      </div>
    ) : (
      label
    );

    return (
      <button
        ref={ref}
        className={clsx(styles.button, {
          [styles.disabled]: disabled,
          [styles.active]: !disabled,
          [styles.loading]: loading,
          [styles.secondary]: secondary,
        })}
        onClick={onClick}
        disabled={disabled || loading}
      >
        {loading ? (
          <div className={styles.spinner}></div>
        ) : (
          <div className={styles.content}>{children}</div>
        )}
      </button>
    );
  }
);

export default Button;
