import { RefObject, useEffect, useRef } from 'react';

const useScrollIntoView = <T extends HTMLElement = HTMLButtonElement>(
  isIntersecting: boolean,
  options = { threshold: 1.0 }
): RefObject<T> => {
  const elementRef = useRef<T>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const observerCallback = (
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          elementRef.current?.scrollIntoView({ behavior: 'smooth' });
          observer.disconnect();
        }
      });
    };

    if (elementRef.current) {
      const observer = new IntersectionObserver(observerCallback, options);
      observer.observe(elementRef.current);
      return () => observer.disconnect();
    }
  }, [isIntersecting, options]);

  return elementRef;
};

export default useScrollIntoView;
