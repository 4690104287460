import MoneyHashHeadless, { IntentDetails } from '@moneyhash/js-sdk/headless';
import * as Sentry from '@sentry/react';
import { useCallback } from 'react';

export interface Params {
  moneyHashInstance: MoneyHashHeadless<'payment'>;
  intentId: string;
  currency: string;
  countryCode: string;
  amount: number;
  onResult: (res: { success: boolean; message?: string }) => void;
}

export const usePaymentHandlers = (params: Params) => {
  const { intentId, amount, currency, moneyHashInstance, countryCode, onResult } = params;

  if (moneyHashInstance === null) {
    throw new Error('moneyHashInstance is undefined');
  }

  if (!intentId) {
    throw new Error('Intent should be defined');
  }

  if (!currency) {
    throw new Error('Currency should be defined');
  }

  if (!countryCode) {
    throw new Error('Country code should be defined');
  }

  if (!amount) {
    throw new Error('Amount should be defined');
  }

  const payWithApplePay = useCallback(() => {
    moneyHashInstance.payWithApplePay({
      intentId,
      countryCode,
      currency,
      amount,
      onError: () => {
        onResult({ success: false });
      },
      onComplete: () => {
        onResult({ success: true });
      },
    });
  }, [onResult, intentId, currency, countryCode, amount, moneyHashInstance]);

  const renderUrl = useCallback(
    (response: IntentDetails<'payment'>) => {
      if (response.state === 'URL_TO_RENDER') {
        if (
          response?.stateDetails &&
          'url' in response.stateDetails &&
          typeof response.stateDetails.url === 'string'
        ) {
          moneyHashInstance.renderUrl({
            url: response.stateDetails.url,
            intentId,
            renderStrategy: 'REDIRECT',
          });
        } else {
          onResult({ success: false, message: 'URL is not provided' });
        }
      }

      if (response.transaction.status === 'purchase.successful') {
        onResult({ success: true });
      } else {
        onResult({
          success: false,
          message: `Transaction status: ${response.transaction.status || 'undefined'} `,
        });
      }
    },
    [onResult, intentId, moneyHashInstance.renderUrl]
  );

  const payWithCard = useCallback(
    async (accessToken: string) => {
      if (!intentId) {
        console.error('intentId is null');
        return;
      }

      await moneyHashInstance
        .submitForm({
          intentId,
          accessToken,
          paymentMethod: 'CARD',
        })
        .then(renderUrl)
        .catch(err => {
          const errorInfo = err
            ? Object.values(err).join(', ').replace(/\./g, '')
            : 'Payment failed';
          Sentry.captureMessage(`submitForm Payment failed ${errorInfo}`);
          onResult({ success: false, message: errorInfo });
        });
    },
    [intentId, moneyHashInstance, onResult, renderUrl]
  );

  const payWithGooglePay = useCallback(() => {
    moneyHashInstance
      .payWithGooglePay({
        intentId,
        onCancel: () => {
          onResult({ success: false, message: 'Payment is canceled' });
        },
      })
      .then(res => {
        if (res && res.transaction.status === 'purchase.successful') {
          onResult({ success: true });
        } else {
          onResult({ success: false, message: res?.transaction?.responseMessage || '' });
        }
      })
      .catch(() => {
        onResult({ success: false });
      });
  }, [onResult, moneyHashInstance, intentId]);

  return {
    payWithCard,
    payWithApplePay,
    payWithGooglePay,
  };
};
