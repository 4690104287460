import { VITE_APP_ENV } from '@/constants/constants';
import QueryClientProvider from '@/context/QueryClient/QueryClientProvider';
import Checkout from '@/screens/Checkout/Checkout';
import Entry from '@/screens/Entry/Entry';
import NotFound from '@/screens/NotFound/NotFound';
import PaymentConfirmation from '@/screens/PaymentStatus/PaymentConfirmation';
import PaymentFailure from '@/screens/PaymentStatus/PaymentFailure';
import * as Sentry from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <QueryClientProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={Entry} />
          <Route path="/entry" element={<Checkout />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/checkout/success/*" element={<PaymentConfirmation />} />
          <Route path="/checkout/failed/*" element={<PaymentFailure />} />
        </Routes>
      </BrowserRouter>
      {VITE_APP_ENV !== 'prod' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
}

const AppWithProfiler = Sentry.withProfiler(App);
// ts-prune-ignore-next
export default AppWithProfiler;
