import styles from './BrandSkeleton.module.scss';

const BrandSkeleton = () => {
  return (
    <div className={styles.root}>
      <div className={styles.imageWrapper}></div>
    </div>
  );
};

export default BrandSkeleton;
