import SummarySkeleton from '@/components/Skeletons/SummarySkeleton';
import SummaryRow from '@/components/Summary/SummaryRow';
import SummaryTotal from '@/components/Summary/SummaryTotal';
import { currency } from '@/constants/constants';
import { useBill } from '@/hooks/useBill';
import { usePayment } from '@/hooks/usePayment';
import formatNumber from '@/utils/formatNumber';
import { useEffect, useRef } from 'react';
import Cashback from './Cashback';
import styles from './Summary.module.scss';

interface Props {
  haveToShowCashbackBanner?: boolean;
}

const Summary = ({ haveToShowCashbackBanner = false }: Props) => {
  const { isLoading, data, isError } = useBill();
  const { paymentState, setPaymentState, selectedTipAmount } = usePayment();
  const previousTotalRef = useRef(paymentState.total);
  const tipAmount = selectedTipAmount || 0;

  useEffect(() => {
    if (data && previousTotalRef.current !== data.total + tipAmount) {
      const updatedTotal = data.total + tipAmount;
      previousTotalRef.current = updatedTotal;
      setPaymentState({ ...paymentState, total: updatedTotal });
    }
  }, [data, paymentState, tipAmount, setPaymentState]);

  if (isLoading) {
    return <SummarySkeleton />;
  }

  if (isError || !data) {
    return null;
  }

  return (
    <div className={styles.root}>
      <h2>Payment Summary</h2>

      {haveToShowCashbackBanner && (
        <Cashback currency={currency} amount={formatNumber(data.estimatedCashback)} />
      )}

      <SummaryRow
        label="Subtotal"
        value={`${currency} ${formatNumber(data.total + data.grossDiscount)}`}
      />
      {data.grossDiscount > 0 && (
        <SummaryRow
          discount
          label="Discount"
          value={`- ${currency} ${formatNumber(data.grossDiscount)}`}
        />
      )}
      <SummaryRow label="Tips" value={`${currency} ${formatNumber(tipAmount)}`} />
      <SummaryTotal
        label="Total Amount"
        value={`${currency} ${formatNumber(paymentState.total)}`}
      />
    </div>
  );
};

export default Summary;
